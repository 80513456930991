//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions , mapMutations } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'
import firebase from 'firebase/app';
import 'firebase/auth';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import PhoneUnverifiedPage from '@/modules/auth/components/phone-unverified-page.vue';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  components: {
    [PhoneUnverifiedPage.name]: PhoneUnverifiedPage,
    VuePhoneNumberInput
  },
  data() {
    return {
      step: 1,
      isTyping: false,
      Phonenumber:'',
      model: {
        rememberMe: true,
      },
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      CCode:'',
      isCCode:'',
      formatInternational: ''

    };
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      // signedIn: 'auth/signedIn'
    }),
    fields() {
      return fields;
    },
    isRTL(){
      return i18n.locale == 'ar'
    }
  },
  methods: {
    ...mapMutations ({
      AUTH_START:'auth/AUTH_START' ,
      AUTH_END:'auth/AUTH_END'
    }),
    ...mapActions ({
      signPhoneNumber:'auth/signPhoneNumber'
    }),
    i18n(key, args) {
        return this.$t(key, args);
    },  
    onInputChange() {
      this.Phonenumber
        ? (this.isTyping = true)
        : (this.isTyping = false);
    },
    prev() {this.step -= 1},
    next() {this.step += 1},

    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      this.AUTH_START()
      window.recaptchaVerifier =  new firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
      });
      let appVerifier =   window.recaptchaVerifier;
      let newphone = "+2" + this.Phonenumber
      await firebase.auth().signInWithPhoneNumber(this.formatInternational, appVerifier).then((confirmationResult) => {  
        console.log('confirmationResult', confirmationResult)
        localStorage.setItem("verfId",confirmationResult.verificationId)
        localStorage.setItem('phoneNum', newphone)
        // routerAsync().push('/auth/phone-unverified')
        debugger
        this.next();
        window.confirmationResult = confirmationResult;
      }).catch(function (error) {
        console.log(error)
      });
    },

    onUpdate(data) {
      this.formatInternational = data.formatInternational
    },
  },
};
